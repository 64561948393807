import React from 'react'
import ReactDOM from 'react-dom/client';
import Layout from './Layout/layout.tsx'
import './pages/global.less'
import { Provider } from 'react-redux';
import store from './store/Index.ts';
import { useTranslation } from './locales/i18n.ts';
import App from './App.tsx'

const root = ReactDOM.createRoot((document.getElementById('root') as Element));

function Index({ pageProps }:any) {
  const { t, locale, changeLocale } = useTranslation();
  return <Layout t={t} locale={locale} changeLocale={changeLocale}>
    <App t={t} {...pageProps} />
  </Layout>
}
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Index />
    </Provider>
  </React.StrictMode>
);

// export default App;

